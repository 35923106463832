import * as React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { BiFolderOpen } from "react-icons/bi"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Sidebar from "../components/sidebar"
import MainWrapper from "../components/mainWrapper"

const ArticleIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="All posts" />
        <Bio />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="All posts" />
      <div className="lg:flex lg:justify-between">
        <section>
          <MainWrapper>
            <ol className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {posts.map(post => {
                const title = post.frontmatter.title || post.fields.slug
                return (
                  <li key={post.fields.slug} className="bg-white p-4 group">
                    <article
                      className="post-list-item"
                      itemScope
                      itemType="http://schema.org/Article"
                    >
                      <header>
                        <h2 className="font-bold group-hover:opacity-80 duration-300">
                          <Link
                            to={post.fields.slug}
                            itemProp="url"
                            className="pb-4 block"
                          >
                            <span itemProp="headline">{title}</span>
                          </Link>
                        </h2>
                      </header>
                      <Link
                        to={post.fields.slug}
                        className="block"
                        itemProp="url"
                      >
                        <GatsbyImage
                          image={getImage(post.frontmatter.hero)}
                          alt={post.frontmatter.title}
                          itemProp="image"
                          className="hover:opacity-80 duration-300"
                        />
                      </Link>
                      <div className="flex text-sm font-bold text-gray-700 mt-4 justify-between">
                        <Link
                          to={`/category/${post.frontmatter.category}/`}
                          className="inline-block hover:opacity-80"
                        >
                          <BiFolderOpen className="inline-block text-blue-400 w-5 h-5 mr-1" />
                          {post.frontmatter.category}
                        </Link>
                        <time
                          dateTime={post.frontmatter.date}
                          className="inline-block"
                        >
                          {post.frontmatter.date}
                        </time>
                      </div>
                    </article>
                  </li>
                )
              })}
            </ol>
          </MainWrapper>
        </section>
        <section>
          <Sidebar />
        </section>
      </div>
    </Layout>
  )
}

export default ArticleIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "YYYY-MM-DD")
          title
          category
          hero {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                width: 1200
                height: 720
                formats: [AUTO, WEBP, AVIF]
                layout: CONSTRAINED
              )
            }
          }
        }
      }
    }
  }
`
